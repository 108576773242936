/* stylelint-disable */

// General
@import '../assets/mixins/mixins.scss';
@import '../assets/style/container.scss';
// @import '../assets/font/font.scss';

// Elements
@import '../elements/PhoneInput/PhoneInput.scss';
@import '../elements/InputPassword/InputPassword.scss';
@import '../elements/ControllerForm/ControllerForm.scss';
@import '../elements/ButtonsEl/ButtonsEl.scss';
@import '../elements/Tab/Tab.scss';
@import '../elements/ButtonLanguages/ButtonLanguages.scss';
@import '../elements/AcceptTerms/AcceptTerms.scss';

// Components
@import './Roulette/Roulette.scss';
@import './ChestSelect/ChestSelect.scss';
@import './AgeVerificationModal/AgeVerificationModal';

// Auth && Form
@import './Auth/Auth.scss';
@import './Auth/Verification/Verification.scss';

// Pages Layout
@import '../pages/_Page/Page.scss';
@import '../pages/_Layout/Layout.scss';

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family:
    // Gordita,
    Helvetica,
    Arial,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // user-select: none;

  font-size: 14px;
  background: var(--root--background);

  &::-webkit-scrollbar {
    display: none;
  }

  * {
    ::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }

    ::-webkit-scrollbar-thumb {
      background: rgb(255 255 255 / 25%);
      border-radius: 10px;
      opacity: 0.3;
    }
  }

  a {
    text-decoration: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:focus,
:focus-visible {
  outline: none;
}

.view {
  background: var(--body--background);

  @include flexbox($justifyContent: flex-start, $flow: column nowrap);
  // min-height: 100vh;
  // min-height: 100dvh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  &.profile {
    background: var(--profile--background);
  }
}

.lobby-general {
  >div {
    @include pxToEm(margin-bottom, 30);

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.btn {
  &--back-history {
    min-width: max-content;

    span {
      @include font(12, 12, 500);
      text-transform: capitalize;
    }
  }

  &--show-more {
    span {
      @include font(12, 12, 500);
      text-transform: capitalize;
    }
  }

  .load-more {
    @include flexbox($justifyContent: center, $alignItems: center);
  }
}
