.chest_select-section {
  @include flexbox($flow: row nowrap, $justifyContent: space-around, $gap: 15px);
  @include pxToEm(padding, 100 15 50 15);

  background: url('../../../public/uploads/en_desktop_chest_select_background.jpg') no-repeat center / cover;

  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;

  &__showed {
    position: absolute;
    top: 0;
    left: 10%;
    width: 40%;
    height: 70vh;


    @media (orientation: portrait) {
      left: 0;
      width: 90%;
      height: 60vh;
      margin: 0 5%;
    }

    & img {
      position: absolute;
    }

    &__shadow {
      width: 100%;
      bottom: 0;
    }

    &__chest {
      left: 50%;
      bottom: 10%;
      width: 70%;
      transform: translate(-50%, 0px);
      animation: moveUpDown 2s infinite;

      @keyframes moveUpDown {
        0% {
          transform: translate(-50%, 0px);
        }

        50% {
          transform: translate(-50%, 10px);
        }

        100% {
          transform: translate(-50%, 0px);
        }
      }
    }

    &__light {
      width: 100%;
      height: 100%;
    }
  }

  &__description_first_time {
    position: absolute;
    top: 0;
    right: 10%;
    width: 40%;
    height: 70vh;
    @include flexbox($justifyContent: center, $alignItems: center, $flow: column);
    text-align: center;
    padding: 0 5%;

    @media (orientation: portrait) {
      width: 100%;
      top: 60vh;
      right: 0;
      height: 20vh;
    }

    &>* {
      @include font(36, 30, 500, #fff);

      @media (orientation: portrait) or (max-width: $mobile) {
        @include font(24, 18, 500, #fff);
      }
    }

    & h2 {
      text-transform: uppercase;
    }

    & button {
      @include pxToEm(border-radius, 5);
      @include pxToEm(padding, 12 30);
      background-color: #E31351;
      border: none;
      box-shadow: 0 4px 30px 0 #E3135180;

      @media (orientation: portrait) {
        @include pxToEm(padding, 6 15);
      }

      cursor: pointer;

      &:disabled {
        filter: grayscale(1);
      }
    }
  }

  &__description_result {
    position: absolute;
    top: 0;
    right: 5%;
    width: 40%;
    height: 70vh;
    @include flexbox($justifyContent: center, $alignItems: flex-start, $flow: column, $gap: 16px);
    text-align: center;
    padding: 0 5%;

    @media (orientation: portrait) {
      width: 100%;
      top: 60vh;
      right: 0;
      height: 20vh;
    }

    &__prize {
      @include flexbox($alignItems: flex-start, $flow: column, $gap: 12px);
      @include font(20, 16, 400, #fff);
      @include pxToEm(padding, 20);
      position: relative;
      width: 100%;
      background: linear-gradient(0deg, rgba(117, 137, 244, 0.22), rgba(117, 137, 244, 0.22)),
        linear-gradient(289.65deg, #8E3FE4 -70.69%, #171E39 55.64%);
      border: 1px solid #7589F438;
      box-shadow: 0 4px 0 0 #6731EE;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      border-radius: 5px;

      & p {
        margin: 0;
      }

      & img {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
      }
    }

    & button {
      @include pxToEm(border-radius, 5);
      @include pxToEm(padding, 12 30);
      background-color: #E31351;
      border: none;
      box-shadow: 0 4px 30px 0 #E3135180;
      color: #fff;
      @include pxToEm(letter-spacing, 0.66);
      cursor: pointer;

      @media (orientation: portrait) {
        @include pxToEm(padding, 6 15);
      }

      &:disabled {
        filter: grayscale(1);
        cursor: auto;
      }
    }
  }

  &__footer {
    position: absolute;
    margin: 0 5%;
    width: 90%;
    @include pxToEm(bottom, 20);


    & p {
      margin: 0;
      @include font(16, 12, 400, #fff);
    }

    & hr {
      height: 0.5px;
      border-color: #22273C;
    }

    &__chest_list {
      @include flexbox($gap: 6px);
      width: 100%;
      position: relative;

      &__item {
        width: 10%;
        position: relative;
        border: 0.5px solid #7589F438;
        background: linear-gradient(174.93deg, #0B112A 4.08%, #1C233E 98.71%),
          linear-gradient(0deg, rgba(117, 137, 244, 0.22), rgba(117, 137, 244, 0.22));
        border-radius: 5px 5px 0 0;
        border-bottom: 3px solid #7589F4;


        &.selected {

          border-bottom: none;

          &:after {
            content: '';
            width: 100%;
            height: 3px;
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            box-shadow: 0 0 10px 0 #2D45F2;
            background-color: #2D45F2;
          }
        }

        & img {
          width: 100%;
          @include pxToEm(padding, 5 6);
        }
      }

      &__line {
        position: absolute;
        left: 0;
        @include pxToEm(width, 3);
        height: 100%;
        background-color: #F404D2;
        animation-fill-mode: forwards;

        @for $i from 1 through 5 {
          &__duration-#{$i} {
            animation-duration: $i+s;
          }
        }


        @for $i from 1 through 10 {
          $randomNumber: random() * 2% + 4%;
          $leftFinishPosition: $i*10-$randomNumber;

          &__finished-#{$i} {
            left: $leftFinishPosition;
          }

          &__move-to-#{$i} {
            animation-name: moveLeftRight-#{$i};

            @keyframes moveLeftRight-#{$i} {
              0% {
                left: 0;
              }

              20% {
                left: 100%;
              }

              40% {
                left: 0;
              }

              60% {
                left: 100%;
              }

              80% {
                left: 0;
              }

              100% {
                left: $leftFinishPosition;
              }
            }
          }
        }
      }
    }
  }
}
